import React, {useEffect} from 'react';

import { Mixpanel } from '@/utils/mixpanel';
import { getSitesStaticPaths, getSiteStaticProps } from '@/src/utils/pageUtils';
import SipjoyAppContext from '@/src/contexts/SipjoyAppContext';
import SitePage from '@/src/components/SitePage';

import NotFound from "@/components/views/supportingPages/NotFound"
import { getPageAppContextProps } from '@/src/contexts/SiteContext';
import { ProducerPage } from '@/src/components/views/producerPage';
import { getBrandFromService } from '@/src/services/sipjoyService';

const homePage = ({siteContext, brandPageData}) => {
  useEffect(() => {
    Mixpanel.track('Home Page');
  });
  if (!siteContext) {
    console.error('Could not load site properties')
    return <NotFound/>
  }
  let page, topBarLogo;
  if (brandPageData) {
    topBarLogo = brandPageData?.producer?.template?.top_bar_logo?.src
    page = <ProducerPage pageData={brandPageData} />
  } else {
    const home = getSiteHomePage(siteContext)
    page = home && <SitePage content={home.page} />
  }

  
  if (!page) {
    console.error('Home page is not defined for this site')
    return <NotFound/>
  }

  return (
    <SipjoyAppContext
      siteContext={siteContext}
      topBarLogo={topBarLogo}
      {...getPageAppContextProps(page)}
    >
      {page}
    </SipjoyAppContext>
  )
};

export default homePage;


function getSiteHomePage(siteContext) {
  return siteContext.site?.home_page
}

export async function getStaticProps({ params }) {
  const siteStaticProps = await getSiteStaticProps({ params })
  const siteContext = siteStaticProps?.props?.siteContext;
  const home = getSiteHomePage(siteContext)
  if (home?.brand?.slug) {
    const brand = await getBrandFromService({
      siteKey: siteContext.site.key,
      slug: home.brand.slug,
      includeSiteProperties: false,
    })
    const pageData = JSON.parse( JSON.stringify({
      producer: brand,
      products: brand.products
    }));
    siteStaticProps.props.brandPageData = pageData;
  }
  
  return siteStaticProps;
}

export async function getStaticPaths() {
  return getSitesStaticPaths();
}