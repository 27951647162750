import React from 'react';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Container from '@/src/common/Container';
import PropTypes from 'prop-types';
import MarkdownView from '../Markdown/MarkdownView';

const GenericHero = ({title, subtitle}) => {
  const theme = useTheme();
  const sectionsBGColor = theme.palette.primary.main;
  return (
    <Box bgcolor={sectionsBGColor} position={'relative'}>
      <Container position="relative" zIndex={2}>
        <Typography
          variant="h4"
          gutterBottom
          align={'center'}
          sx={{
            fontWeight: 700,
            color: theme.palette.primary.contrastText,
            marginTop: '20px',
          }}
        >
          {title}
        </Typography>
        {subtitle && (
          <Box
            display={'flex'}
            flexDirection={'column'}
            alignItems={'center'}
            sx={{ color: 'white', fontFamily: 'MariaConnected' }}
          >
            <MarkdownView>{subtitle}</MarkdownView>
          </Box>
        )}
      </Container>
      <Box
        component={'svg'}
        preserveAspectRatio="none"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        viewBox="0 0 1921 273"
        sx={{
          position: 'absolute',
          width: '100%',
          left: 0,
          bottom: -30,
          right: 0,
          zIndex: 1,
          height: '20%',
        }}
      >
        <path
          fill={theme.palette.background.default}
          d="M0,0c0,0,934.4,93.4,1920,0v100.1H0L0,0z"
        ></path>
      </Box>
    </Box>
  )
};

GenericHero.propTypes = {
  title: PropTypes.string,
};
export default GenericHero;
