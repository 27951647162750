/* eslint-disable no-unused-vars */
import React, { useEffect } from 'react';
import {Box} from '@mui/material';

import Container from '@/src/common/Container';
import Loading from '../Loading';
import GenericHero from '../GenericHero';
import { useRouter } from 'next/router';
import { Mixpanel } from '@/src/utils/mixpanel';
import { ImageHero } from '../Hero';
import Meta  from '@/src/components/Meta';
import SitePageComponent from './SitePageComponent';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useSite } from '@/src/contexts/SiteContext';
import Link from 'next/link';


const SitePage = ({ content }) => {
  const theme = useTheme();
  const router = useRouter();
  const { site } = useSite()
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  const alternateBackground = theme.palette.background.level1;

  useEffect(() => {
    if(!router.isReady) return;
    if (content?.page_name) {
      Mixpanel.track('Page', {
        uid: content?.page_name,
      })
    }
  }, [router.asPath, router.isReady]);

  
  const hero = (!isMd && content?.hero_mobile) || content?.hero;
  let heroComp;
  if (hero && !content.hide_hero) {
    heroComp = hero.image ? (
      <ImageHero
        title={hero.text?.title}
        subtitle={hero.text?.body}
        heroImg={hero.image}
      />
    ) : (
      <GenericHero title={hero.text.title} subtitle={hero.text.body} />
    )
  }
  if (hero?.link) {
    heroComp = (
      <Link href={hero?.link}>
        <a>
          {heroComp}
        </a>
      </Link>
    )
  }
  
  let mainComp;
  if (!content) {// still loading content from server
    mainComp = (
      <Box margin={10}>
        <Loading wineAnimation={true} />
      </Box>
    );
  } else {
    mainComp = (
      <Box
        display={'flex'}
        flexDirection={'column'}
        alignItems={'center'}
      >
        {(content.body || []).map((item, key) => {
          return (
            <Box
              key={key}
              width="100%"
              sx={{
                backgroundColor: 
                  item.backgroundColor ||
                  (key % 2 === 1 && alternateBackground),
              }}
            >
              {item.fullSize ? (
                <SitePageComponent item={item} />
              ) : (
                <Container position={'relative'} top={0}>
                  <SitePageComponent item={item} />
                </Container>
              )}
            </Box>
          )
        })}
      </Box>
    )
  }
  

  return (
    <Box>
      {content?.seo ? (
        <Meta
          site={site}
          title={content.seo.title}
          description={content.seo.description}
          keywords={content.seo.keywords}
          previewImage={content.seo.preview_image?.src}
        />
      ) : null}
      <Box boxShadow={4} borderRadius={2}>
        {heroComp}

        {mainComp}
        
      </Box>
    </Box>
  )
};


export default SitePage;
